@font-face {
  font-family: "Red Hat Display";
  src: local("RedHatDisplay-Medium"), url("./RedHatDisplay-Medium.ttf");
}

@font-face {
  font-family: "Red Hat Display Bold";
  src: local("RedHatDisplay-Bold"), url("./RedHatDisplay-Bold.ttf");
}

@font-face {
  font-family: "Open Sans Light";
  src: local("OpenSans-Light"), url("./OpenSans-Light.ttf");
}

@font-face {
  font-family: "Open Sans";
  src: local("OpenSans-Regular"), url("./OpenSans-Regular.ttf");
}

@font-face {
  font-family: "Open Sans Bold";
  src: local("OpenSans-SemiBold"), url("./OpenSans-SemiBold.ttf");
}
